.user-list {
    width: 80%;
    margin: 50px auto;
    text-align: center;
  }
  
  .user-list h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .user-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-list th, .user-list td {
    padding: 12px 15px;
    border: 1px solid #ddd;
  }
  
  .user-list th {
    background-color: #007bff;
    color: white;
  }
  
  .user-list tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .status {
    text-align: center;
    margin-top: 50px;
    font-size: 1.5em;
    color: #555;
  }
  
  .status.error {
    color: red;
  }